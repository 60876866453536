body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px; /* Default font size */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Media Queries - from largest to smallest for better specificity */
@media screen and (max-width: 1199px) {
  body {
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  body {
    font-size: 12px;
  }
  
  #root {
    min-height: calc(100vh - 56px); /* Adjust for mobile header height */
  }
}
